import styled from 'styled-components'
import Img from 'gatsby-image'
import theme, { media } from '../../helpers/theme'

const Content = styled.div`
  position: relative;
  overflow: hidden;
  padding: 50px 0;
  height: calc(120vw + 400px + ${theme.space[8]}px);
  margin-top: ${theme.space[8]}px;
  margin-bottom: ${theme.space[4]}px;
  ${media('md')}{
    margin: 0;
    height: unset;
  }
`
Content.Wave = styled.img`
  position: relative;
  grid-column: span 1 / span 1;
  width: 100%;
  left: 50%;
  transform: translateX(-50%);
  min-width: 1800px;
  display: none;
  transition: all 0.5s;
  ${media('md')} {
    display: unset;
    min-width: 2200px;
  }
  ${media('2xl')} {
    min-width: 2560px;
  }
`
Content.Container = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
`
Content.Grid = styled.div`
  position: relative;
  margin: 0;
  transform: translateY(-70px);
  grid-column: span 1 / span 1;
  width: 100%;
  max-width: 1010px;
  display: grid;
  z-index: 1;
  grid-template-rows: 120vw 400px;
  ${media('md')} {
    margin: 0 ${theme.space[8]}px;
    grid-template-rows: 1fr;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    transform: translateY(-75px);
  }
`
Content.TextContainer = styled.div`
  grid-column: auto;
  position: relative;
  display: flex;
  align-items: center;
  text-align: center;
  margin: 0;
  background-color: ${theme.colors.accent};
  z-index: 1;
  padding: 0 ${theme.space[8]}px;
  padding-bottom: ${theme.space[24]}px;
  ${media('md')}{
    margin-top: -${theme.space[8]}px;
    text-align: left;
    background-color: unset;
    padding-bottom: 0;
  }
  ${media('lg')} {
    padding-left: 0;
    margin-top: 0;
  }
  ${media('xl')} {
  }
`
Content.Text = styled.div`
  margin: 0;
  ${media('md')} {
    margin-top: -${theme.space[4]}px;
  } 
  ${media('lg')} {
    margin-top: -${theme.space[10]}px;
  }
`
Content.Title = styled.h2`
  font-weight: bold;
  font-size: ${theme.font['2xl']};
  ${media('md')} {
    font-size: ${theme.font['3xl']};
  } 
  ${media('lg')} {
    font-size: ${theme.font['4xl']};
  }
`
Content.Description = styled.p`
  font-size: ${theme.font['md']};
  ${media('md')} {
    font-size: ${theme.font['lg']};
  }
  ${media('xl')} {
    font-size: ${theme.font['xl']};
  }
`

Content.MiddleWave = styled.img`
  width: 100%;
  height: 20vw;
  position: absolute;
  display: unset;
  bottom: -1px;
  left: 0;
  z-index: 1;
  ${media('md')} {
    display: none;
  }
`
Content.BottomWave = styled.img`
  width: 100%;
  height: 15vw;
  position: absolute;
  display: unset;
  bottom: -1px;
  left: 0;
  z-index: 1;
  ${media('md')} {
    display: none;
  }
`
Content.TopWave = styled.img`
  height: 25vw;
  width: 110%;
  top: 0;
  position: absolute;
  display: unset;
  left: 0;
  z-index: 1;
  transform: scaleY(-1);
  ${media('md')} {
    display: none;
  }
`

Content.Product = styled(Img)`
  position: absolute !important;
  z-index: 10;
  display: none;
  bottom: 130px;
  width: 275px;
  transition: all 0.5s;
  ${media('md')} {
    display: inline-block;
    right: calc(50% - 250px);
  }
  ${media('xl')} {
    right: calc(50% - 280px);
    width: 300px;
  }
  ${media('2xl')} {
    width: 400px;
    right: calc(50% - 380px);
  }
`

Content.ImageContainer = styled.div`
  position: relative;
  margin-left: -36%;
  grid-row-start: 1;
  grid-row-end: 2;
  ${media('md')} {
    margin-left: 0;
    grid-column-start: 2;
    grid-column-end: 3;
  }
`

Content.Image = styled(Img)`
  position: relative;
  overflow: visible !important;
  height: unset;
  width: 140%;
  ${media('md')} {
    height: 420px;
    width: unset;
  }
  ${media('2xl')} {
    min-height: 500px;
    height: 21vw;
  }
  img {
    object-fit: unset !important;
    width: 100%;
    ${media('md')} {
      width: auto !important;
    }
  }
`

export default Content
