import React from 'react'
import Content from './masked-content.styles'
import YellowWave from '../../images/yellow-wave.svg'
import TopWave from '../../images/top-wave.svg'
import BottomWave from '../../images/bottom-wave.svg'
import { graphql, useStaticQuery } from 'gatsby'

const MaskedContent = () => {
  const { phone, product } = useStaticQuery(
    graphql`
      query {
        phone: file(relativePath: { eq: "phone.png" }) {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        product: file(relativePath: { eq: "product.png" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 800) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  )

  return (
    <Content>
      <Content.Container>
        <Content.Product
          fluid={product.childImageSharp.fluid}
          alt='product image'
        />
        <Content.Grid>
          <Content.TextContainer>
            <Content.BottomWave src={BottomWave} alt='wave svg' />
            <Content.Text>
              <Content.Title>Power Your Ecommerce</Content.Title>
              <Content.Description>
                Your online store is no longer limited to your website. Creative
                Mail makes it easy to send ecommerce campaigns that drive
                customers to shoppable WooCommerce pages increasing store sales.
              </Content.Description>
            </Content.Text>
          </Content.TextContainer>
          <Content.ImageContainer>
            <Content.TopWave src={BottomWave} alt='wave svg' />
            <Content.MiddleWave src={TopWave} alt='wave svg' />
            <Content.Image
              fluid={phone.childImageSharp.fluid}
              alt='phone image'
            />
          </Content.ImageContainer>
        </Content.Grid>
      </Content.Container>
      <Content.Wave src={YellowWave} alt='background wave' />
    </Content>
  )
}

export default MaskedContent
