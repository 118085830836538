import React from 'react'
import { DataElementLocations } from '@eig-builder/core-utils/helpers/tagging-helper'
import CallToAction from './call-to-action.style'
import homeSubscribers from './../../images/homeSubscribers.png'
import homeFooterWave from './../../images/homeFooterWave.svg'
import laptop from './../../images/laptop.png'

import bullet1 from './../../images/bullet-1.svg'
import bullet2 from './../../images/bullet-2.svg'
import bullet3 from './../../images/bullet-3.svg'
import bullet4 from './../../images/bullet-4.svg'

const CTAComponent = () => (
  <CallToAction>
    <CallToAction.container>
      <CallToAction.ImgContainer>
        <CallToAction.person src={homeSubscribers} />
      </CallToAction.ImgContainer>
      <CallToAction.textContainer>
        <CallToAction.header>
          <span>Target Subscribers & Customers</span>
        </CallToAction.header>
        <CallToAction.features>
          <div>
            <CallToAction.listBullet first src={bullet1} />
            <span>
              Bounces, opens, clicks, unsubscribes and more are tracked with
              simplified visual analytics.
            </span>
          </div>
          <div>
            <CallToAction.listBullet src={bullet2} />
            <span>
              Target contact audiences, campaigns and specific lists to drive
              brand engagement and audience expansion.
            </span>
          </div>
          <div>
            <CallToAction.listBullet src={bullet3} />
            <span>
              Automatically create and optimize campaigns for all types of
              mobile and desktop devices.
            </span>
          </div>
          <div>
            <CallToAction.listBullet src={bullet4} />
            <span>Leverage our powerful and free CRM tools.</span>
          </div>
        </CallToAction.features>
        <CallToAction.laptop src={laptop} />
      </CallToAction.textContainer>
    </CallToAction.container>
    <CallToAction.wave src={homeFooterWave} />
    <CallToAction.buttonContainer>
      <CallToAction.header>
        <span>Ready to give it a spin?</span>
      </CallToAction.header>
      <CallToAction.text>
        <span>
          Don’t take our word for it, get the plugin to experience Creative Mail
          for yourself.
        </span>
      </CallToAction.text>
      <CallToAction.button
        data-element-location={DataElementLocations.BODY}
        data-element-label='cta.button'
        data-element-id='cta-button-get-started'
        href={process.env.GATSBY_PLUGIN_URL}
      >
        <span>START FREE</span>
      </CallToAction.button>
    </CallToAction.buttonContainer>
  </CallToAction>
)

export default CTAComponent
