import React from 'react'

import Layout from './home-features-section.styles'

import wave from './../../images/home-features-shape.svg'

import bullet1 from './../../images/bullet-1.svg'
import bullet2 from './../../images/bullet-2.svg'
import bullet5 from './../../images/bullet-3.svg'
import bullet6 from './../../images/bullet-4.svg'

const HomeFeaturesSection = () => (
   <Layout>
      <Layout.ai>
         <Layout.title>Easier WordPress Email Marketing</Layout.title>
         <Layout.text>
         Creative Mail was designed especially for WordPress and WooCommerce. It
         uses an intelligent editor that you access from within your WordPress
         Admin Dashboard. It leverages pre-assembled, smart content sections that
         simplify email creation. You focus on the content, and we'll handle the
         rest.
         </Layout.text>
         <Layout.text>
            Get Creative! - It's easy WordPress email marketing
         </Layout.text>
      </Layout.ai>
      <Layout.grid>
         <Layout.wave src={wave} />
         <Layout.content>
            <Layout.person />

            <Layout.features>
               <h3>With Creative Mail</h3>
               <div>
                  <Layout.listBullet first src={bullet5} />
                  <span>
                  New contacts are automatically gathered from your signup forms and
                  WooCommerce stores.
                  </span>
               </div>
               <div>
                  <Layout.listBullet src={bullet6} />
                  <span>
                  We pull-in WordPress blogposts and WooCommerce store products as
                  content suggestions for your campaigns.
                  </span>
               </div>
               <div>
                  <Layout.listBullet src={bullet1} />
                  <span>
                  You get free access to the Unsplash photo library to make amazing
                  campaigns with award winning images.
                  </span>
               </div>
               <div>
                  <Layout.listBullet src={bullet2} />
                  <span>
                  You can restyle automated WooCommerce store emails ensuring your
                  brand is captured in every email you send.
                  </span>
               </div>
            </Layout.features>
         </Layout.content>
      </Layout.grid>
   </Layout>
)

export default HomeFeaturesSection
