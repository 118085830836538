import React from 'react'

import SEO from './../components/seo'
import Layout from './../components/layout'
import Footer from './../components/footer'
import HomeHero from './../components/home-hero'
import CTAComponent from './../components/call-to-action'
import MaskedContent from './../components/masked-content'
import HomeFeaturesSection from './../components/home-features-section'
import SaleModal from '../components/sale-modal'

const Home = () => {
  return (
    <Layout isWhiteLogo>
      <SEO
        title='Email marketing for WordPress'
        pageType='home'
        flowType='none'
      />
      <HomeHero />
      <HomeFeaturesSection />
      <MaskedContent />
      <CTAComponent />
      <Footer />
      <SaleModal />
    </Layout>
  )
}

export default Home
