import React from 'react'

import { DataElementLocations } from '@eig-builder/core-utils/helpers/tagging-helper'

import HomeHeroLayout from './home-hero.styles'

import hero from './../../images/Home-header-image.png'
import DesktopWave from './../../images/Home-header-shape.svg'
import MobileWave from './../../images/Home-header-shape-mobile.svg'

const HomeHero = () => (
  <HomeHeroLayout>
    <HomeHeroLayout.mobileWave src={MobileWave} />
    <HomeHeroLayout.desktopWave src={DesktopWave} />
    <HomeHeroLayout.content>
      <HomeHeroLayout.contentItem>
        <HomeHeroLayout.title>
          Intelligent e-mail marketing for WordPress and WooCommerce
        </HomeHeroLayout.title>
        {/* <HomeHeroLayout.subtitle>
          Powered by Constant Contact
        </HomeHeroLayout.subtitle> */}
        <HomeHeroLayout.desktopButton>
          <HomeHeroLayout.button
            href={process.env.GATSBY_PLUGIN_URL}
            data-element-location={DataElementLocations.HERO}
            data-element-label='home.hero.ctaDesktop'
            data-element-id='creativemail-home-hero-cta-desktop'
          >
            Get started for FREE
          </HomeHeroLayout.button>
        </HomeHeroLayout.desktopButton>
      </HomeHeroLayout.contentItem>
      <HomeHeroLayout.imageItem>
        <HomeHeroLayout.image src={hero} />
        <HomeHeroLayout.mobileButton>
          <HomeHeroLayout.button
            href={process.env.GATSBY_PLUGIN_URL}
            data-element-location={DataElementLocations.HERO}
            data-element-label='home.hero.ctaMobile'
            data-element-id='creativemail-home-hero-cta-mobile'
          >
            Get started for FREE
          </HomeHeroLayout.button>
        </HomeHeroLayout.mobileButton>
      </HomeHeroLayout.imageItem>
    </HomeHeroLayout.content>
  </HomeHeroLayout>
)

export default HomeHero
